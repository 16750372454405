@import "reset";
@import "sidebar";



.PhoneInput {
  border: 1.5px solid #b6bac1;
  padding: 5px 10px;
  border-radius: 8px;
  width: 100%;
  color: black;
}

.PhoneInput input {
  padding: 5px;
  border: none;
  outline: none;
  color: black;
}
.PhoneInput .PhoneInputCountry .PhoneInputCountrySelect option,
.PhoneInput .PhoneInputCountry .PhoneInputCountrySelect {
  border: none !important;
  outline: none !important;
}
.PhoneInputCountryIcon--border {
  box-shadow: none;
}
.tableAddRateType input {
  height: 100%;
  width: 100%;
  padding: 15px;
  outline: none;
}
.tableAddRateType td {
  padding: 5px;
}
