.sidebar::-webkit-scrollbar {
  display: none;
}
.layout {
  display: flex;
}
main {
  width: 100%;
  color: #ccc;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  min-height: 100vh;
  flex-shrink: 0;
  background-color: #0e0e23;
  overflow: auto;
  color: #fff;
  transition: all 0.5s;
}
.sidebar-item {
  transition: 0.5s;
  margin-bottom: 20px;
  color: #fff;
}
.title {
  font-size: 14px;
}
.sidebar-item:hover .title {
  transition: 0.5s;
}
.sidebar-title:hover span .title {
  opacity: 1;
  transform: translateX(10px);
  transition: 0.5s;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}
.sidebar-title span {
  display: flex;
  align-items: center;
  transition: all 0.5s;
}
.sidebar-title span .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #fff !important;
}
.sidebar-title .arrow-icon {
  cursor: pointer;
  transition: all 0.5s;
}
.sidebar-item.open > .sidebar-title .arrow-icon {
  transform: rotate(90deg);
}
.sidebar-content {
  overflow-x: hidden;
  overflow-y: auto;
  width: 0;
  transition: all;
  transition-duration: 300ms;
  
}

.sidebar-item.open > .sidebar-content {
  display: block;
  background-color: #333;
  // border-radius: 10px 0 0 10px;
  padding: 20px 0 0 0;
  // margin: 10px 0;
  position: fixed;
  top: 5rem;
  bottom: 0;
  left: 100px;
  border-radius: 0 20px 20px 0;
  width: 250px;

  // padding-top: 5rem;
    transition: all;
  transition-duration: 300ms;
}
.sidebar-item.open > .active {
  height: 500px;
  background-color: "rebeccapurple";
}
.active {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}
.active .sidebar-title {
  background-color: #eaedf7;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: 30px 0 0 30px;
  border: 0px solid transparent;
  width: inherit;
  padding: 5px;
}

.sidebar-item.expand > .sidebar-title .arrow-icon {
  transform: rotate(90deg);
}

.sidebar-item.expand > .sidebar-content {
  height: auto;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px 0 0 10px;
  padding: 20px 0 0 0;
  margin: 10px 0;
}

.sidebar-item.expand > .active {
  height: 500px;
  background-color: "rebeccapurple";
}

/* .active .sidebar-title::before {
  content: "";
  position: absolute;
  top: -50px;
  right: -5px;
  width: 50px;
  height: 50px;
  background: #0e0e23;
  border-bottom-right-radius: 30px;
  z-index: 56;
}
.active .sidebar-title::after {
  content: "";
  position: absolute;
  bottom: -50px;
  right: 0px;
  width: 50px;
  height: 50px;
  background: #0e0e23;
  border-top-right-radius: 30px;
}
.active span {
  position: relative;
}
.active span::before {
  content: "";
  position: absolute;
  top: -20px;
  right: -180px;
  width: 50px;
  height: 50px;
  background: #eaedf7;
  border-bottom-right-radius: 30px;
  z-index: 55;
}
.active span::after {
  content: "";
  position: absolute;
  bottom: -40px;
  right: -180px;
  width: 50px;
  height: 50px;
  background: #eaedf7;
}
 */
.active .sidebar-item .title {
  color: #FBB02E;
}
.active .icon {
  font-size: 18px;
  line-height: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  background-color: #FBB02E;
}
.active .sidebar-content .s-child {
  height: 100%;
  display: block;
}


.activeSide {
  color: #FBB02E;
}

.activeSide > .icon {
  background-color: #FBB02E;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  padding: 5px;
}