// @import '../../public/assets/fonts/Lato/Lato.ttf';

// @font-face {
//     font-family: "Lato";
//     src:  url("../../public/assets/fonts/Lato/Lato.ttf")format("truetype"),
//     url("../../public/assets/fonts/Lato/Lato.woff2")format("woff2"),
//     url("../../public/assets/fonts/Lato/Lato.eot?#iefix")format("embedded-opentype")
// }


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato";
}
body {
  background-color: #eaedf7;
}

::-webkit-scrollbar {
          display: none;
      }

      
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.roll {
  animation: spin 1s infinite;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px !important;
  margin: 0 !important;
}

body::-webkit-scrollbar {
  display: none;
}
